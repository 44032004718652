
import Vue from "vue";
import "@/assets/CSS/EstiloGeneral.css";
import "@/assets/CSS/EstiloGeneralVue.css";
import "@/assets/CSS/EstiloColorClaro.css";
import "@/assets/CSS/EstiloColorOscuro.css";
import Header from "@/components/Header.vue";
import HomeService from "@/services/home.service";

import jQuery from "jquery";
let $ = jQuery;

export default Vue.extend({
  name: "FinalChat",
  components: {
    'Header': Header,
  },
  data() {
    return {
      claroOscuro:String(localStorage.getItem('claroOscuro')),
      originBot:String(localStorage.getItem('originBot')),
      pais:String(localStorage.getItem('pais')),
      urlRedirigir:"",
      centerHeight: document.documentElement.clientHeight,
    };
  },
  created() {
    this.cargaEstilos();
  },
  mounted() {
    window.onresize = () => {
      this.centerHeight = document.documentElement.clientHeight;
      let aux: any = $(window);
      let total = this.centerHeight;
      aux.height(total);
      let body = $('body');
      body.height(this.centerHeight);
    }
  },
  methods: {
    
    cargaEstilos(){
            HomeService.CargaInicial().then((resp) => {
                this.claroOscuro = resp.data.claroOscuro;
                this.originBot = resp.data.originBot;
                this.pais = resp.data.pais;
                localStorage.setItem("claroOscuro", resp.data.claroOscuro);
                localStorage.setItem("originBot", resp.data.originBot);
                localStorage.setItem("pais", resp.data.pais);
                localStorage.setItem("urlRedirigir", resp.data.urlRedirigir);
                this.urlRedirigir = resp.data.urlRedirigir;
            }).catch((error) =>{
                console.error("Error al realizar la carga inicial s ",error);
            });
    },
  },
});
